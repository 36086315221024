const color = {
  black: '#413E39',
  white: '#fff',
  darkWhite: '#f4f4f4',
  darkerWhite: '#E1E1E1',
  darkGrey: '#9F998C',
  grey: '#C8C8C8',
  lightGrey: '#D3D3D3',
  lighterGrey: '#FCFCFC',
  brown: '#6F685E',
  lightBlue: '#3F4CD5',
  blue: '#2D3155',
  darkBlue: '#232748',

  primary: '#FB5237',
  secondary: '#3F4CD5',

  shopping: '#3F4CD5',
  restaurant: '#FB5237',
  other: '#9F998C',
  facebook: '#4763B4',
  twitter: '#00ACEE',

  green: '#00C440',
  shadowModal: '#2D3254',
  map: {
    available: '#E9E9E9',
    unavailable: '#999999',
    unavailable2019: '#6C6E70'
  },

  // New brand colors
  brand: {
    primary: '#05425E',
    accent: '#FD6F48',
    grey: '#808B91',
    lightGrey: '#EBEBEB',
    lightGrey2: '#D9DCDE',
    brown: '#874A13',
    lightBrown: '#A38C77',
    white: '#FFFFFF',
    lightBlue: '#94C8F0'
  }
};

export default color;
