import styled from 'styled-components';

const ButtonSubmit = styled.button.attrs({
  type: 'submit'
})`
  font-size: 1.8rem;

  width: ${props => props.theme.input.size}px;
  height: ${props => props.theme.input.size}px;

  padding: 0;

  color: currentColor;
  background: transparent;
  text-align: right;

  border: none;

  cursor: pointer;
`;

export default ButtonSubmit;
