export const size = {
  size104: '104px',
  size47: '47.87px',
  size64: '64px',
  size71: '71.63px',
  size51: '51.87px',
  size44: '44px',
  size38: '38px',
  size30: '30px',
  size26: '26px',
  size23: '23px',
  size22: '22px',
  size18: '18px',
  size16: '16px',
  size15: '15px',
  size14: '14px',
  size13: '13px'
};

export const height = {
  size124: '124.8px',
  size76: '76.8px',
  size71: '71.63px',
  size62: '62.25px',
  size57: '57.45px',
  size52: '52.8px',
  size45: '45.6px',
  size36: '36px',
  size31: '31.2px',
  size30: '30.8px',
  size27: '27.6px',
  size22: '22.4px',
  size19: '19.6px',
  size18: '18.2px'
};
