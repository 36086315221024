import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow: hidden;
`;

const Arc = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Arc.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default Arc;
