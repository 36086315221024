import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.button`
  background-color: transparent;
  border: ${props => `1px solid ${props.theme.color.lightGrey}`};
  border-radius: 22px;
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.font.medium};
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 8px 13px;
`;

const ButtonMore = ({ children, onClick }) => (
  <Root onClick={onClick}>{children}</Root>
);

ButtonMore.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default ButtonMore;
