import { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

const keyframe = {
  fadeInUp
};

export { fadeInUp };

export default keyframe;
