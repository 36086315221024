import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import { media } from '../../styles/media';
import Text from '../Typography/Text';
import theme from '../../theme';
import Arc from '../Image/Arc';
import { translate } from 'react-i18next';
import Link from '../Link/Link';

const Wrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || 305}px;
  min-width: ${({ minWidth }) => minWidth || 305}px;
  position: relative;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};

  ${({ hasHover }) =>
    hasHover &&
    `
    &:hover {
      cursor: pointer;

      .card-image {
        transform: scale(1.1);
      }
    }
  `};
`;

const BadgeImg = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
  width: ${({ width }) => width || 140}px;
  height: ${({ height }) => height || 140}px;
  object-fit: cover;
  z-index: 2;
`;

const ButtonsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `};
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;

  ${media.tablet`
    margin-bottom: 0px;
    margin-right: 60px;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;

  width: ${({ width }) => width || 305}px;
  height: ${({ height }) => height || 305}px;

  ${({ isNew }) =>
    isNew &&
    `
    &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: ${theme.color.brand.accent};
    z-index: 1;
  }
  `} &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.5) 16.61%,
        rgba(0, 0, 0, 0) 55.75%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 0.3s ease-in-out;
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width || 130}px;
  height: ${({ height }) => height || 130}px;
  object-fit: contain;
  z-index: 1;
`;

const Date = styled.p`
  font-family: ${theme.font.brand.accent};
  font-size: 22px;
  color: ${theme.color.brand.white};
  text-transform: uppercase;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
`;

const NewTag = styled.span`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-family: ${theme.font.brand.accent};
  font-size: 22px;
  color: ${theme.color.brand.white};
  text-transform: uppercase;
  text-align: center;
  background-color: ${theme.color.brand.accent};
  padding: 5px 10px;
  z-index: 1;
`;

const Card = ({
  card,
  maxWidth,
  minWidth,
  fullHeight,
  isNew,
  isHeart,
  i18n,
  t
}) => {
  const {
    title,
    description,
    date,
    image,
    logo,
    link,
    ctaLeft,
    ctaRight
  } = card;

  const renderCard = link => {
    return (
      <Wrapper
        maxWidth={maxWidth}
        minWidth={minWidth}
        fullHeight={fullHeight}
        hasHover={link}
      >
        {isHeart && (
          <BadgeImg
            src={`/static/images/shops/icon/${i18n?.language ||
              'en'}/heart.svg`}
            alt="Badge heart"
          />
        )}

        <Arc>
          <ImageWrapper
            isNew={isNew}
            width={image?.width}
            height={image?.height}
          >
            {image?.url && (
              <Image src={image?.url} alt={image?.alt} className="card-image" />
            )}

            {logo?.url && <Logo src={logo?.url} alt={logo?.alt} />}

            {date && <Date>{date}</Date>}

            {isNew && <NewTag>{t('common:tag.new')}</NewTag>}
          </ImageWrapper>
        </Arc>

        {title && (
          <Text
            variant="heading3"
            color={theme.color.brand.primary}
            as="h3"
            marginTop="20px"
          >
            {title}
          </Text>
        )}

        {description && (
          <Text variant="body2" color={theme.color.brand.grey} marginTop="10px">
            {description}
          </Text>
        )}

        <ButtonsWrapper>
          {ctaLeft?.label && (
            <StyledButton iconType={ctaLeft?.iconType} href={ctaLeft?.url}>
              {ctaLeft?.label}
            </StyledButton>
          )}

          {ctaRight?.label && (
            <Button iconType={ctaRight?.iconType} href={ctaRight?.url}>
              {ctaRight?.label}
            </Button>
          )}
        </ButtonsWrapper>
      </Wrapper>
    );
  };

  return (
    <>{link ? <Link href={link}>{renderCard(link)}</Link> : renderCard()}</>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number
    }),
    logo: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    }),
    link: PropTypes.string,
    ctaLeft: PropTypes.shape({
      label: PropTypes.string,
      iconType: PropTypes.string,
      url: PropTypes.string
    }),
    ctaRight: PropTypes.shape({
      label: PropTypes.string,
      iconType: PropTypes.string,
      url: PropTypes.string
    })
  }),
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  fullHeight: PropTypes.bool,
  isNew: PropTypes.bool,
  isHeart: PropTypes.bool,
  i18n: PropTypes.object,
  t: PropTypes.func
};

export default translate()(Card);
