import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from '../../styles/media';
import styled from 'styled-components';

const Button = styled.a`
  display: flex;
  align-items: center;
  border: 1px ${props => props.theme.color.brand.lightGrey} solid;
  height: 60px;

  margin: 0 7.5px;

  ${media.tablet`
      margin: 0 15px 0 0;
  `};

  text-decoration: none;
`;

const StyledText = styled.span`
  display: block;
  padding: 8px 8px;
  color: ${props => props.theme.color.brand.primary};
  font-family: ${props => props.theme.font.heading4.fontFamily};
  font-size: ${props => props.theme.font.heading4.fontSize};
  line-height: ${props => props.theme.font.heading4.lineHeight};

  ${media.tablet`
    padding: 17.5px 20px;
  `};
`;

const ShareButton = ({ label, type, href }) => {
  return (
    <Button href={href} target="_blank">
      <Image
        src={`/static/images/icons/${type}.png`}
        width={60}
        height={60}
        objectFit="contain"
      />
      <StyledText>{label}</StyledText>
    </Button>
  );
};

ShareButton.defaultProps = {
  label: 'Partager',
  type: 'facebook'
};

ShareButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['facebook', 'twitter']),
  href: PropTypes.string
};

export default ShareButton;
