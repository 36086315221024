import layout from './layout';
import font from './font';
import color from './color';
import input from './input';
import keyframe from './keyframe';
import spacing from './spacing';

const theme = {
  layout,
  font,
  color,
  spacing,
  input,
  keyframe
};

export default theme;
