import styled from 'styled-components';

const Link = styled.a`
  display: inline-block;
  padding: ${props => props.theme.layout.gutter / 2}px 0;

  font-family: ${props => props.theme.font.bold};
  font-size: 1.25rem;
  letter-spacing: 0.13rem;
  text-transform: uppercase;
  line-height: 1;

  text-decoration: none;

  color: currentColor;
  background: transparent;
  border: none;
  outline: none;

  cursor: pointer;
`;

export default Link;
