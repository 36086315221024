import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import font, { TEXT_TAGS, TEXT_VARIANTS } from '../../theme/font';

const StyledText = styled.p`
  font-family: ${({ fontFamily, theme }) =>
    fontFamily || theme.font.brand.primary};
  color: ${({ color, theme }) => color || theme.color.brand.grey};
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.body.fontSize};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight || theme.font.body.lineHeight};

  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  transform: ${({ transform }) => transform && transform};
  text-transform: ${({ transform }) => transform && transform};
  text-align: ${({ align }) => align || 'left'};
`;

const Text = ({
  variant,
  color,
  as,
  align,
  children,
  marginTop,
  marginBottom,
  paddingTop,
  transform
}) => {
  const fontStyle = font[variant];

  return (
    <StyledText
      as={as}
      color={color}
      {...fontStyle}
      paddingTop={paddingTop}
      marginTop={marginTop}
      marginBottom={marginBottom}
      align={align}
      transform={transform}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf(TEXT_VARIANTS),
  as: PropTypes.oneOf(TEXT_TAGS),
  color: PropTypes.string,
  children: PropTypes.any,
  paddingTop: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  transform: PropTypes.string,
  align: PropTypes.string
};

export default Text;
