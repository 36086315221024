import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';

const Root = styled.a`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  height: 80px;

  color: ${props => props.theme.color.brand.primary};
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 0 30px 0 rgba(0, 21, 106, 0.2);

  text-decoration: none;

  ${media.tablet`
    height: 100px;
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 60px;
  height: 100%;

  color: ${props => props.theme.color.white};

  ${media.tablet`
    width: 100px;
  `};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Wrapper = styled.p`
  width: 100%;
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: 18px;
  line-height: 25.2px;
  padding: 20px;
`;

class IButton extends React.PureComponent {
  render() {
    const { children, img, href } = this.props;

    return (
      <Root href={href}>
        <IconWrapper>
          <Image src={img} />
        </IconWrapper>
        <Wrapper>{children}</Wrapper>
      </Root>
    );
  }
}

IButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  href: PropTypes.string,
  img: PropTypes.string.isRequired
};

export default IButton;
