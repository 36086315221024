import { height, size } from './size';

export const TEXT_TAGS = ['h1', 'h2', 'h3', 'p', 'span'];
export const TEXT_VARIANTS = [
  'heading1',
  'heading2',
  'heading3',
  'body',
  'body2',
  'date',
  'description'
];

const FONT_PRIMARY = 'Helvetica, system-ui, sans-serif';
const FONT_SECONDARY = 'Gustavo, system-ui, sans-serif';
const FONT_TERTIARY = 'Francoeur, system-ui, sans-serif';

const font = {
  regular: 'BrandonGrotesqueWeb-Regular, system-ui, sans-serif',
  medium: 'BrandonGrotesqueWeb-Medium, system-ui, sans-serif',
  bold: 'BrandonGrotesqueWeb-Bold, system-ui, sans-serif',
  black: 'BrandonGrotesqueWeb-Black, system-ui, sans-serif',
  icon: 'ghd',
  size: {
    title: '3.2rem',
    subtitle: '1.2rem',
    input: '1.7rem',
    home: {
      title: '51.87px',
      subtitle: '26px'
    }
  },
  brand: {
    primary: FONT_PRIMARY,
    secondary: FONT_SECONDARY,
    accent: FONT_TERTIARY
  },
  fontWeight: 400,
  heading1: {
    fontFamily: FONT_SECONDARY,
    fontSize: size.size64,
    lineHeight: height.size76
  },
  heading2: {
    fontFamily: FONT_SECONDARY,
    fontSize: size.size38,
    lineHeight: height.size45
  },
  heading3: {
    fontFamily: FONT_SECONDARY,
    fontSize: size.size22,
    lineHeight: height.size30
  },
  heading4: {
    fontFamily: FONT_SECONDARY,
    fontSize: size.size15,
    lineHeight: height.size18
  },
  body: {
    fontFamily: FONT_PRIMARY,
    fontSize: size.size16,
    lineHeight: height.size22
  },
  body2: {
    fontFamily: FONT_PRIMARY,
    fontSize: size.size14,
    lineHeight: height.size19
  },
  description: {
    fontFamily: FONT_SECONDARY,
    fontSize: size.size14,
    lineHeight: height.size19
  },
  date: {
    fontFamily: FONT_TERTIARY,
    fontSize: size.size18,
    lineHeight: height.size19
  },
  homepage: {
    mainTitle: {
      fontSize: size.size64,
      lineHeight: height.size76,
      mobileFontSize: size.size44,
      mobileLineHeight: height.size52
    },
    subTitle: {
      fontSize: size.size26,
      lineHeight: height.size31,
      mobileFontSize: size.size23,
      mobileLineHeight: height.size27
    },
    sectionTitle: {
      fontSize: size.size51,
      lineHeight: height.size62,
      mobileFontSize: size.size30,
      mobileLineHeight: height.size36
    },
    coverTitle: {
      fontSize: size.size104,
      lineHeight: height.size124,
      mobileFontSize: size.size47,
      mobileLineHeight: height.size57
    },
    curvedTitle: {
      fontSize: size.size71,
      lineHeight: height.size71,
      mobileFontSize: size.size71,
      mobileLineHeight: height.size71
    }
  }
};

export default font;
