import styled from 'styled-components';

const LinkBadge = styled.a`
  display: inline-block;

  height: 30px;
  padding: 0 ${props => props.theme.layout.gutter / 2}px;

  font-family: ${props => props.theme.font.medium};

  font-size: 1.4rem;
  letter-spacing: 0.4px;
  line-height: 30px;

  text-align: center;
  text-decoration: none;

  background: transparent;
  color: ${props => props.theme.color.darkGrey};

  border: 1px solid ${props => props.theme.color.lightGrey};
  border-radius: 30px;
  outline: none;

  cursor: pointer;

  & + & {
    margin-left: ${props => props.theme.layout.gutter / 2}px;
  }
`;

export default LinkBadge;
