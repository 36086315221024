import styled from 'styled-components';

const FloatingButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;

  width: ${props => (props.size ? props.size : '32')}px;
  height: ${props => (props.size ? props.size : '32')}px;

  color: ${props => props.theme.color.brand.primary};

  text-decoration: none;
`;

export default FloatingButton;
